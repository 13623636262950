import React, { ReactElement, useEffect, useState } from 'react';
import {
  createStyles,
  StyleRules,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Carousel } from 'antd';
import { navigate } from 'gatsby';

import ft1 from '../../../images/homeImg/ft1.png';
import ft2 from '../../../images/homeImg/ft2.png';
import ft3 from '../../../images/homeImg/ft3.png';
import ft4 from '../../../images/homeImg/ft4.png';
import specialData from '../../../utils/special';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    filterItem: {
      borderRadius: '5px',
      fontWeight: 'bold',
      width: '100%',
      height: '315px',
      color: '#333',
      background: '#fff',
      overflow: 'auto',
    },
    menuSpecial: {
      position: 'relative',
      minWidth: '18rem',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    rightBanner: {
      margin: '0 auto',
      overflow: 'hidden',
      borderRadius: '5px',
      width: '851px',
      height: '315px',

      [theme.breakpoints.down('sm')]: {
        width: '320px',
        height: '220px',
      },
    },
    borderCss: {
      borderRadius: '5px',
    },
    iconSize: {
      height: '1rem',
      width: '1rem',
    },
    bannerImg: {
      width: '100%',
      height: '100%',
    },
    itemLabel: {
      color: '#9b9bbe',
      cursor: 'pointer',
      display: 'flex',
      textAlign: 'center',
      padding: '0.8rem',
      flexWrap: 'nowrap',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
        color: '#ffffff',
        background: '#9b9bbe',
      },
      '& .hover': {
        boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
      },
    },
    itemLabelHover: {
      textAlign: 'center',
      padding: '1rem',
      borderRadius: '1rem',
      boxShadow: '0 4px 16px 0 rgba(153,153,153,0.2)',
      transition: 'all 0.3s ease-in-out',
    },
    filterItemContent: {
      position: 'absolute',
      top: '4rem',
      height: '10rem',
      borderRadius: '1rem',
      padding: '0.5rem 2rem',
      background: '#fff',
      boxShadow: '0px 8px 20px 0px rgba(51, 51, 51, 0.1)',
      zIndex: 999,
      width: '100%',
    },
    childContainer: {
      padding: '1rem',
      maxHeight: '60vh',
      width: '50vw',
      display: 'flex',
      overflow: 'auto',
      flexWrap: 'wrap',
      background: '#ffffff',
    },
    child: {
      padding: '0.5rem 1rem',
      color: '#333',
      cursor: 'pointer',
      '&:hover': {
        color: '#ffff',
        fontWeight: 'bold',
        background: '#9b9bbe',
      },
    },
    childTitle: {
      margin: '0.5rem',
      color: '#9b9bbe',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    childActive: {
      color: '#242f65',
      fontWeight: 'bold',
    },
    filterItemLabel: {
      fontWeight: 600,
      padding: '0.5rem 0',
      minWidth: '3rem',
    },
    filterBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchContainer: {
      width: '100%',
      display: 'flex',
      padding: '2rem 0',
      '& .ant-input-group .ant-input-group-addon': {
        background: 'unset !important',
        border: 'none !important',
        padding: '0 !important',
      },
      '& .ant-input': {
        height: '3rem !important',
      },
    },
    searchButton: {
      marginLeft: '1rem',
    },
    scrollBtn: {
      padding: '0',
      position: 'absolute',
      bottom: 15,
      right: 15,
      width: '23px',
    },
    tooltipSet: {
      padding: '0',
      backgroundColor: '#ffffff',
      fontSize: '0.75rem',
    },
    contentImg: {
      background: '#9b9bbe',
      borderRadius: '5px',
      width: '851px',
      height: '315px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '220px',
      },
    },
    itemImg: {
      width: '851px',
      height: '315px',
      borderRadius: '5px',

      [theme.breakpoints.down('sm')]: {
        width: '320px',
        height: '220px',
      },
      objectFit: 'cover',
    },
    container: {
      overflow: 'hidden',
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '0 1rem',
      },
    },
  });

interface ProfessionalItem {
  label: string;
  value: string;
  children?: ProfessionalItem[];
  showChildren?: boolean;
}

const ConsultantViewHeader = ({ classes }: Props): ReactElement => {
  const [renderProfessionalList, setRenderProfessionalList] = useState<ProfessionalItem[]>([]);

  const [childrenList, setChildrenList] = useState<ProfessionalItem[]>([]);

  const onMouseEnter = (item: ProfessionalItem) => {
    const findIndex = specialData.findIndex(renderItem => renderItem.value === item.value);
    const childrenListData = specialData[findIndex].children || [];
    setChildrenList(childrenListData);
  };

  const setList = () => {
    const list = specialData.map(item => {
      return {
        ...item,
        showChildren: false,
      };
    });
    setRenderProfessionalList(list);
  };
  const childItemDom = (
    <div className={classes.childContainer}>
      {childrenList.map((child, index) => {
        return (
          <div key={index}>
            <div className={classes.childTitle}>{child.label}</div>
            {child.children?.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={classes.child}
                onClick={() => {
                  navigate(`/consultant/publicBiddingProjects`);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    setList();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.menuSpecial}>
        <div className={classes.filterItem}>
          {renderProfessionalList.map(item => {
            return (
              <Tooltip
                key={item.value}
                title={childItemDom}
                interactive={true}
                arrow
                placement="right"
                classes={{ tooltip: classes.tooltipSet }}
              >
                <div className={classes.itemLabel} onMouseEnter={() => onMouseEnter(item)}>
                  {item.label}
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className={classes.rightBanner}>
        <Carousel autoplay>
          <div className={classes.contentImg}>
            <img src={ft1} alt="t1" className={classes.itemImg} />
          </div>
          <div className={classes.contentImg}>
            <img src={ft2} alt="t1" className={classes.itemImg} />
          </div>
          <div className={classes.contentImg}>
            <img src={ft3} alt="t1" className={classes.itemImg} />
          </div>
          <div className={classes.contentImg}>
            <img src={ft4} alt="t1" className={classes.itemImg} />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ConsultantViewHeader);
